import { AuthState } from '@aws-amplify/ui-components';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Auth } from 'aws-amplify';

export type UserState = {
  user: CognitoPayload | undefined;
  authState: AuthState | undefined;
};

export type CognitoPayload = {
  ['cognito:username']: string;
  ['cognito:organizationKey']: string;
  email: string;
  exp: number;
};

const initialState: UserState = {
  user: undefined,
  authState: undefined,
};

export const signOutAsync = createAsyncThunk('user/signOut', async () => {
  await Auth.signOut();
});

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    signIn: (state, action: PayloadAction<UserState>) => {
      state.authState = action.payload.authState;
      state.user = action.payload.user;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signOutAsync.fulfilled, (state) => {
      state.authState = undefined;
      state.user = undefined;
    });
  },
});

export const { signIn } = userSlice.actions;

export default userSlice.reducer;
