import { AuthState } from '@aws-amplify/ui-components';
import { Box, Container, Flex, Icon, Link, Menu, MenuButton, Text } from '@chakra-ui/react';
import { routerActions } from 'connected-react-router';
import {
  Button,
  CaretDownIcon,
  Heading as EgHeading,
  LogOutIcon,
  Menu as EgMenu,
  PeopleIcon,
  Popover,
  Position,
} from 'evergreen-ui';
import React, { memo, useEffect, useState } from 'react';
import { FiCalendar, FiMail, FiBookOpen } from 'react-icons/fi';
import { CgProfile } from 'react-icons/cg';
import { useAppDispatch, useAppSelector } from '../hooks';
import { signOutAsync } from '../slice/userSlice';
import './shell.scss';
import { Auth } from 'aws-amplify';
import { useLocation } from 'react-router-dom';

const NavItem = ({ icon, title, active, onClick }: any) => {
  return (
    <Flex mt={30} flexDir="column" w="100%">
      <Menu placement="right">
        <Link
          backgroundColor={active ? '#cbe7ff' : 'inherit'}
          p={3}
          borderRadius={2}
          _hover={{ textDecor: 'none', backgroundColor: '#e8f4ff' }}
          w={'auto'}
          onClick={onClick}
        >
          <MenuButton w="100%">
            <Flex>
              <Icon as={icon} fontSize="xl" color={active ? '#82AAAD' : 'gray.500'} />
              <Text ml={5} display={'flex'}>
                {title}
              </Text>
            </Flex>
          </MenuButton>
        </Link>
      </Menu>
    </Flex>
  );
};

const navs = [
  { icon: FiCalendar, title: 'レッスン登録', active: false, pathname: '/lessonframes' },
  { icon: FiBookOpen, title: 'レッスン一覧', active: false, pathname: '/lessons' },
  { icon: CgProfile, title: 'MYプロフィール', active: false, pathname: '/profile' },
  { icon: FiMail, title: '問い合わせ', active: false, pathname: '/contact' },
];

export interface IShellProps {
  children: React.ReactNode;
}

export var orgKey = React.createContext('kokoka');
export var currentUserId = React.createContext('');

const getOrgKeyOrCurrentUserId = async (attributeName: string = 'currentUserId') => {
  const authenticatedUser = await Auth.currentAuthenticatedUser().catch((e) => null);
  if (authenticatedUser) {
    const orgKey = await authenticatedUser.attributes['custom:organizationKey'];
    const currentUserId = await authenticatedUser.attributes['sub'];
    return attributeName === 'orgKey' ? orgKey : currentUserId;
  }
  return '';
};

export const Shell: React.NamedExoticComponent<IShellProps> = memo(({ children }) => {
  const dispatch = useAppDispatch();
  const historyState = useAppSelector((state) => state.router);
  const userState = useAppSelector((state) => state.user);
  const [organizationKey, setOrganizationKey] = useState<any>('kokoka');
  const onClickBrand = () => dispatch(routerActions.push({ pathname: '/top' }));
  const onClickProfile = (close: () => void) => {
    // TODO
    close();
  };

  const onClickSignout = async () => {
    await dispatch(signOutAsync());
  };

  const onBlur = (close: () => void) => {
    // TODO
    close();
  };

  useEffect(() => {
    getOrgKeyOrCurrentUserId().then((teacherId) => {
      currentUserId = React.createContext(teacherId);
    });
    return;
  });

  return (
    <>
      <Box
        display="flex"
        height="60px"
        borderBottom="1px solid #ddd"
        justifyContent="space-between"
        boxShadow={'sm'}
        alignItems="center"
      >
        <Box alignItems="center" display="flex" flexWrap="wrap">
          <EgHeading
            size={600}
            onClick={onClickBrand}
            style={{ cursor: 'pointer' }}
            paddingLeft={30}
          >
            {organizationKey}
            {organizationKey == 'kokoka' ? 'にほんご' : ''}
          </EgHeading>
          <br />
          <sub style={{ color: '#3f83bf', marginLeft: '5px', bottom: 'unset' }}>
            {organizationKey !== undefined && organizationKey.length > 0 ? ' | ' : ''}{' '}
            学習支援者管理システム
          </sub>
        </Box>
        <Box hidden={!(userState.authState === AuthState.SignedIn && userState.user)}>
          <Popover
            position={Position.BOTTOM_LEFT}
            bringFocusInside={true}
            content={({ close }) => (
              <EgMenu>
                <EgMenu.Group>
                  <EgMenu.Item
                    icon={PeopleIcon}
                    onBlur={() => onBlur(close)}
                    onClick={() => onClickProfile(close)}
                  >
                    Profile
                  </EgMenu.Item>
                </EgMenu.Group>
                <EgMenu.Divider />
                <EgMenu.Group>
                  <EgMenu.Item icon={LogOutIcon} onClick={onClickSignout} intent="danger">
                    Signout
                  </EgMenu.Item>
                </EgMenu.Group>
              </EgMenu>
            )}
          >
            <Button iconAfter={CaretDownIcon} marginRight={12} intent="info">
              {userState.user?.email}
            </Button>
          </Popover>
        </Box>
      </Box>
      <Flex>
        <Flex
          pos="sticky"
          borderRight="1px solid #ddd"
          boxShadow="sm"
          minW="200px"
          flexDir="column"
          justifyContent="space-between"
          top="0"
          h={'100vh'}
          hidden={!(userState.authState === AuthState.SignedIn && userState.user)}
        >
          <Flex
            position="sticky"
            top="0"
            p="15px"
            flexDir="column"
            w="100%"
            alignItems={'flex-start'}
            as="nav"
          >
            {navs.map((nav) => (
              <NavItem
                key={nav.title}
                icon={nav.icon}
                title={nav.title}
                active={nav.pathname === historyState.location.pathname}
                onClick={() => dispatch(routerActions.push(nav.pathname))}
              />
            ))}
          </Flex>
        </Flex>
        <Container className="Shell-children">{children}</Container>
      </Flex>
    </>
  );
});
